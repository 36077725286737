import { FormEvent, useEffect, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

import { useNotifications } from '../../hooks/useNotifications';
import { ButtonSpinner } from '../common/Spinner';
import { subscribe, SubscriptionType } from '../common/SubscribeEmail';

const navigation = {
  solutions: [
    { name: 'Styrearbeid', href: '/styrearbeid' },
    { name: 'Hjemmeside', href: '/hjemmeside' },
    { name: 'Kommunikasjon', href: '/kommunikasjon' },
    { name: 'Økonomi', href: '/okonomi' },
    { name: 'Se flere løsninger', href: '/alle' },
  ],
  support: [
    { name: 'Kontakt oss', href: '/kontakt' },
    { name: 'Priser', href: '/priser' },
    { name: 'Ofte stilte spørsmål', href: '/hjelpesenter' },
  ],
  other: [
    { name: 'Blogg', href: '/blogg' },
    { name: 'Jobb', href: '/jobb' },
    { name: 'Partnerskap', href: '/partnerskap' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/soliboas/',
      icon: (props: any) => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path
            fillRule='evenodd'
            d='M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z'
            clipRule='evenodd'
          />
        </svg>
      ),
    },
    {
      name: 'Linkedin',
      href: 'https://no.linkedin.com/company/soliboas',
      icon: (props: any) => (
        <svg fill='currentColor' viewBox='0 50 512 512' {...props}>
          <path
            fillRule='evenodd'
            d='M150.65,100.682c0,27.992-22.508,50.683-50.273,50.683c-27.765,0-50.273-22.691-50.273-50.683
        C50.104,72.691,72.612,50,100.377,50C128.143,50,150.65,72.691,150.65,100.682z M143.294,187.333H58.277V462h85.017V187.333z
        M279.195,187.333h-81.541V462h81.541c0,0,0-101.877,0-144.181c0-38.624,17.779-61.615,51.807-61.615
        c31.268,0,46.289,22.071,46.289,61.615c0,39.545,0,144.181,0,144.181h84.605c0,0,0-100.344,0-173.915
        s-41.689-109.131-99.934-109.131s-82.768,45.369-82.768,45.369V187.333z'
            clipRule='evenodd'
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/soliboas',
      icon: (props: any) => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path d='M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84' />
        </svg>
      ),
    },
    {
      name: 'GitHub',
      href: 'https://github.com/Solibo-AS',
      icon: (props: any) => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path
            fillRule='evenodd'
            d='M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z'
            clipRule='evenodd'
          />
        </svg>
      ),
    },
  ],
};

export const Footer = () => {
  const { t } = useTranslation();
  const { genericError, success } = useNotifications();
  const [email, setEmail] = useState<string>('');
  const [sending, setSending] = useState(false);
  const [sendingState, setSendingState] = useState<JSX.Element>(<p>Meld på</p>);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    subscribeNewsletter(formData);
  };
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const subscribeNewsletter = (formData: FormData) => {
    setSending(true);
    subscribe({
      subscriptionType: SubscriptionType.NEWSLETTER,
      formData: formData,
    })
      .then((_) => {
        setEmail('');
        success(t('marketing.newsletter.posted'));
      })
      .catch((e) => {
        genericError(e);
      })
      .finally(() => {
        setSending(false);
      });
  };

  useEffect(() => {
    if (sending) {
      setSendingState(<ButtonSpinner />);
    } else {
      setSendingState(<p>Meld på</p>);
    }
  }, [sending]);

  return (
    <footer className='bg-gray-800' aria-labelledby='footer-heading'>
      <h2 id='footer-heading' className='sr-only'>
        Footer
      </h2>
      <div className='mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16'>
        <div className='xl:grid xl:grid-cols-3 xl:gap-8'>
          <div className='grid grid-cols-2 gap-8 xl:col-span-2'>
            <div className='md:grid md:grid-cols-2 md:gap-8'>
              <div>
                <h3 className='text-sm font-semibold uppercase tracking-wider text-gray-400'>
                  Funksjoner
                </h3>
                <ul className='mt-4 space-y-4'>
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className='text-base text-gray-300 hover:text-white'>
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='mt-12 md:mt-0'>
                <h3 className='text-sm font-semibold uppercase tracking-wider text-gray-400'>
                  Support
                </h3>
                <ul className='mt-4 space-y-4'>
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className='text-base text-gray-300 hover:text-white'>
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='md:grid md:grid-cols-2 md:gap-8'>
              <div>
                <h3 className='text-sm font-semibold uppercase tracking-wider text-gray-400'>
                  Annet
                </h3>
                <ul className='mt-4 space-y-4'>
                  {navigation.other.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className='text-base text-gray-300 hover:text-white'>
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className='mt-8 xl:mt-0'>
            <h3 className='font-semibold uppercase tracking-wider text-gray-400'>
              Meld på nyhetsbrev
            </h3>
            <p className='mt-4 text-base text-gray-300'>
              Få de siste nyheter og oppdateringer om styreportalen.
            </p>
            <form className='mt-4 sm:flex sm:max-w-md' onSubmit={handleSubmit}>
              <label htmlFor='email-address' className='sr-only'>
                Epostadresse
              </label>
              <input
                type='email'
                name='email'
                data-cy={'newsletter-footer-email-input'}
                id='email-address'
                autoComplete='email'
                required
                value={email}
                className='focus:focus-border w-full min-w-0 appearance-none rounded-md border border-transparent bg-white px-4 py-2 text-base text-gray-900 placeholder:text-gray-700'
                placeholder='Skriv inn epost'
                onChange={handleChange}
              />
              <div className='mt-3 rounded-md sm:ml-3 sm:mt-0 sm:shrink-0'>
                <button
                  type='submit'
                  data-cy={'newsletter-footer-button'}
                  className='focus:focus-ring flex w-full items-center justify-center rounded-md border border-transparent bg-emerald-500 px-4 py-2 text-base font-medium text-white hover:bg-emerald-600'
                >
                  {sendingState}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className='mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between'>
          <div className='flex space-x-6 md:order-2'>
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                target='_blank'
                rel='noreferrer'
                className='text-gray-400 hover:text-gray-300'
              >
                <span className='sr-only'>{item.name}</span>
                <item.icon className='size-6' aria-hidden='true' />
              </a>
            ))}
          </div>
          <p className='mt-8 text-base text-gray-400 md:order-1 md:mt-0'>
            &copy; 2022. Et produkt av{' '}
            <a className='cursor-pointer text-white' href='https://solibo.no'>
              Solibo AS.
            </a>
          </p>
        </div>
      </div>
      <div data-cy={'cookie-consent'}>
        <CookieConsent
          location='bottom'
          buttonText='Jeg samtykker'
          cookieName='cookie-gdpr-consent'
          style={{ background: '#000' }}
          contentStyle={{
            fontFamily: [
              'Segoe UI',
              'Oxygen',
              'Ubuntu',
              'Cantarell',
              'Fira Sans',
              'Droid Sans',
              'Helvetica Neue',
              'sans-serif',
            ].join(','),
          }}
          buttonStyle={{ color: '#000', background: '#fff', fontSize: '14px', borderRadius: '8px' }}
          expires={150}
        >
          {t('gdpr.cookieConsent.main')}{' '}
          <span style={{ fontSize: '11px' }}>{t('gdpr.cookieConsent.detail')}</span>
        </CookieConsent>
      </div>
    </footer>
  );
};
